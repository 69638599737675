import React from "react"

import { Link } from "gatsby"

import DogFeeds from "../components/dog-feeds"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AdoptionFee from "../components/shared/adoption-fee"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/available-dogs-banner-bg.jpg"

const AvailableDogsPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Available Dogs" />
    <InnerBanner theme="white" image={banner} title="Available Dogs" subtitle="Dogs" />
    <AdoptionFee note="If you are interested in meeting one of our Labs, the next step is to complete an adoption application.">
      <p>Below is a list of our available Labrador Retrievers. If you don’t find the new friend you’re looking for on this page, check out our <Link to="/coming-soon-dogs/"><strong>Coming Soon Labs</strong></Link>. We typically have approximately 30 Labs in our rescue program at any given time. Labs come into the program and are adopted daily, so this list is ever-changing.</p>
      <p>Click each picture to find what our foster families have to say about each of our Labs. Since we don’t have a shelter, all our dogs are in foster homes. Our foster parents provide as much information as possible on the behaviors and traits of our dogs.</p>
    </AdoptionFee>
    <DogFeeds type="available" per_page={100}/>
  </Layout>
}

export default AvailableDogsPage