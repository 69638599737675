import { Link } from "gatsby"
import React from "react"
import "./adoption-fee.css"

const AdoptionFee = ({children,note})=>{

    return <section className="adption-fee">
        <div className="content">
            {children}
                 <div className="adopt-fee">
                <h3>Adoption Fees</h3>
                 <p><span className="price">$400</span> <span className="text">for puppies (age under 6 months old)</span></p>
                <p><span className="price">$325</span> <span className="text">for adults (age 6 months and older)</span></p>
              <Link className="btn-primary" to="/adoption-process/">Apply for Adoption</Link>
            </div>
            <p>{note}</p>

        </div>
        
    </section>
}

export default AdoptionFee